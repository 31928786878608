export const config = {
    ENV: '$CI_ENVIRONMENT_NAME',
    API_URL: '$TF_OUT_api_url/api/v1',
    DEV_TOOLS: '$DEV_TOOLS',
    LOGGING_LEVEL: '$LOGGING_LEVEL',
    LOGGING_HANDLERS: '$LOGGING_HANDLERS',
    LOGGING_GRAFANA_URL: '$LOGGING_GRAFANA_URL',
    LOGGING_GRAFANA_APP_NAME: '$LOGGING_GRAFANA_APP_NAME',
    LOGGING_RUM_ENABLED: '$LOGGING_RUM_ENABLED',
    LOGGING_TRACING_ENABLED: '$LOGGING_TRACING_ENABLED',
    PATENT_LAB_UI_URL: '$PATENT_LAB_UI_URL',
    TEMPLATING_ENGINE_UI_URL: '$TEMPLATING_ENGINE_UI_URL',
    ENTITY_PORTAL_UI_URL: '$ENTITY_PORTAL_UI_URL',
    CLUSTER_SVC_URL: '$TF_OUT_cluster_api_url',
    PATENT_RESOLVER_URL: '$TF_OUT_pidr_api_url',
    LITIGATION_SEARCH_URL: '$LITIGATION_SEARCH_URL',
    ENTITY_API_URL: '$TF_OUT_entity_svc_url',
    AUTH_CLIENT_ID: '$TF_OUT_auth_client_id',
    AUTH_DOMAIN: '$TF_OUT_auth_domain',
    AUTH_AUDIENCE: '$TF_OUT_auth_audience',
    UNLEASH_URL: '$UNLEASH_URL',
    UNLEASH_CLIENT_KEY: '$UNLEASH_CLIENT_KEY',
    UNLEASH_REFRESH_INTERVAL: '$UNLEASH_REFRESH_INTERVAL',
    UNLEASH_APP_NAME: '$UNLEASH_APP_NAME',
    UNLEASH_ENVIRONMENT: '$UNLEASH_ENVIRONMENT',
};
